import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import Layout from "../components/layout"

export default function CompletedDonation() {
  const data = useStaticQuery(graphql`
    query tivpLetterhead {
      letterhead: file(name: { eq: "tivpLetterhead" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
        sig: file(name: {eq: "jeffDsignature"}) {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
    }
  `)

  const letterhead = data.letterhead.childImageSharp.gatsbyImageData
  const jeffSig =  data.sig.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <div className="w-full min-h-screen flex flex-col justify-center phone:py-0 tablet:py-0 py-24 bg-gradient-to-b from-blue-500 to-blue-700 print:bg-white">
        <div className="w-full flex justify-center">
          <div className="w-auto flex-col text-stone-600 flex justify-center bg-white rounded-md phone:rounded-none tablet:rounded-none print:rounded-none phone:border-none tablet:border-none border border-blue-500 print:border-none py-6 pb-12 shadow-lg shadow-gray-800 print:shadow-none">
            <div className="w-full prose font-roboto px-8">
              <div className="flex justify-center">
                <GatsbyImage image={letterhead} />
              </div>
              <p className="mb-4">Dear Donor,</p>
              <p className="mb-2">
                On behalf of The Infinite Voice Project Organization, I would
                like to thank you for your generous donation. The Infinite Voice
                Project relies on the generosity of donors such as yourself to
                help educate people worldwide regarding our planet's Star Nation
                visitors, their many cultures, and their various reasons for
                being on Earth.
                <p className="">
                  And to also foster a right-minded approach of mutual respect,
                  communication and cooperation with our world's Star Nations
                  through education.
                </p>
              </p>
              <p className="mb-4">
                We are truly grateful for your support. Thank you once again.
              </p>

              <p className="mb-2">Sincerely,</p>
              <div className="mb-4"><GatsbyImage image={jeffSig} className="w-1/3 h-auto" /></div>

            </div>
            <div className="w-full flex flex-col items-baseline px-8 mt-4">
              <div className="w-full flex justify-start font-bold">
                Jeff Demmers - Executive Director
              </div>
              <div className="w-full flex justify-start text-sm">
                <a href="mailto:jeffd@theinfinitevoiceproject.org">
                  <sm className="text-stone-500">
                    <span className="pr-1">
                      <FontAwesomeIcon icon={faEnvelope} color="current" />
                    </span>
                    jeffd@theinfinitevoiceproject.org
                  </sm>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
